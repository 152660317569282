$(function(){

    var url = window.location.href;

    if (url.substr(url.lastIndexOf('/') + 1) === "guitar-lessons") {
        $('.nav-section .guitar-lessons').addClass('active');
    }

    $('.has-dropdown').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        var arrowIcon = $(this).find('.fa');
        var lessonLinks = $(this).next('.dropdown');
        lessonLinks.toggleClass('active');
        arrowIcon.toggleClass('rotate');
    });

    // Side Menu Object
    var SideMenu = {
        open: function(){
            $('.menu-toggle').addClass('active');
            $('.nav-side-bar').addClass('active');
            $('body').append(
                '<div class="nav-overlay"></div>'
            );
            $('.nav-overlay').fadeIn(200);
        },
        close: function(){
            $('.menu-toggle').removeClass('active');
            $('.nav-side-bar').removeClass('active');
            $('.nav-overlay').fadeOut(200);
            setTimeout(function(){
                $('.nav-overlay').remove();
            }, 200);
        }
    };

    // Modal Object
    var Modal = {
        open: function(){
            $('body').append(
                '<div class="modal-overlay"></div>'
            );
            $('.suggestion-modal').fadeIn(200);
            $('.modal-overlay').fadeIn(200);
        },
        close: function(){
            var thisModal = $('.suggestion-modal');
            thisModal.fadeOut(200);
            $('.modal-overlay').fadeOut(200);

            $('.modal-input').val('');

            setTimeout(function(){
                $('.modal-overlay').remove();
                $('.request-container').hide();
            }, 200);
        }
    };

    // Events
    $('.menu-toggle').click(function(){
        if($(this).hasClass('active')){
            SideMenu.close();
        }
        else {
            SideMenu.open();
        }
    });

    $('body')
        .on('click', '.nav-overlay', SideMenu.close)
        .on('click', '.modal-overlay, .close-modal', Modal.close);

    // sliding scrollbar function
    function isScrollPast(distanceFromTop) {
        return $(window).scrollTop() > distanceFromTop;
    }

    function getDistanceFromTop(elementToCalculate) {
        if ($(elementToCalculate).is(':visible')) {
            return $(elementToCalculate).parent().offset().top;
        }

        return false;
    }

    var bodyWidth = $(window).width();
    var slidingElm = $('.sliding-function .side-slide');
    var menuHeight = $('.top-bar').height();
    var topFixedSidebarBuffer = 15;

    $(window).resize(function () {
        bodyWidth = $(window).width();
        $(slidingElm).removeClass('fixedSlider');
    });

    var originalDistanceFromTop = getDistanceFromTop(slidingElm);
    $(window).scroll(function () {
        if (bodyWidth > 991) {

            var footerOffsetTop = $('.footer-top').offset().top;

            if (originalDistanceFromTop === false) {
                return;
            }

            if (isScrollPast(originalDistanceFromTop - menuHeight - topFixedSidebarBuffer)) {
                if (!$(slidingElm).hasClass('fixedSlider')) {
                    $(slidingElm).addClass('fixedSlider');
                }

                if (isScrollPast(footerOffsetTop - slidingElm.height() - menuHeight - (topFixedSidebarBuffer * 2))) {
                    slidingElm.css('top', (footerOffsetTop - slidingElm.height() - $(window).scrollTop() - (topFixedSidebarBuffer)));
                } else {
                    slidingElm.css('top', '');
                }
            } else {
                if ($(slidingElm).hasClass('fixedSlider')) {
                    $(slidingElm).removeClass('fixedSlider');
                }
            }
        }
    });
    $(slidingElm).css('width', $(slidingElm).parent().width());
    $(window).resize(function () {
        if (typeof slidingElm !== 'undefined') {
            originalDistanceFromTop = getDistanceFromTop(slidingElm);
            $(slidingElm).css('width', $(slidingElm).parent().width());
            $(window).trigger('scroll');
        }
    });
});